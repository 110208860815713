export enum LOCALE_MATCH_STRATEGY {
  NO = 'NO',
  IGNORE = 'IGNORE',
  PREFER = 'PREFER',
  FILTER = 'FILTER',
}

export enum FREQUENCY_TYPE {
  DAILY = 'DAILY',
  LIMITED = 'LIMITED',
  SEASONAL = 'SEASONAL',
  WEEKLY = 'WEEKLY',
}

export enum RESULT_MIXING_MODE {
  DEFAULT = 'DEFAULT',
  INTERLEAVE = 'INTERLEAVE',
}
