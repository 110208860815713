import React, { createContext, ReactChild, useReducer } from 'react';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import * as API from '../../API';
import { listAdminScreens } from '../../helpers/QueryHelper';
import { addAdminScreen, addScreen } from '../../helpers/MutationHelper';

export type Screen = {
  id: string;
  updatedAt: string;
  createdAt: string;
};

export interface ScreenContextExports {
  appStore: InitialState;
  createAdminScreen: (id: string) => void;
  createScreen: (type: string) => void;
  createAdminScreenLoading: boolean;
  createAdminScreenError: any;
  createScreenLoading: boolean;
  createScreenError: any;
  loading: boolean;
  error: ApolloError | undefined;
};

export interface ScreenActionsContextExports {
  dispatch: React.Dispatch<any>;
};

export const ScreenActionsContext = createContext<
  ScreenActionsContextExports | undefined
>(undefined);

const initialState: InitialState = {
  screens: []
};

export interface InitialState {
  screens: Screen[];
}

export const ScreenContext = createContext<
  ScreenContextExports | undefined
>(undefined);

export interface ScreenProviderProps {
  children?: ReactChild;
};

export let AppState = initialState;
const reducerWrapper = (state: any, action: any) => {
  AppState = reducer(state, action);
  return AppState;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setQueryResponse': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

async function fetchRemainingAdminScreens(
  nextToken: string,
  variables: API.ListScreensQueryVariables,
  fetchMore: any
) {
  let keepFetching: boolean = true,
    adminScreenVersionData: Screen[] = [];

  while (keepFetching) {
    const { data: queryResponse } = fetchMore({
      variables: {
        ...variables,
        nextToken: nextToken,
      },
      fetchPolicy: 'no-cache',
    });

    // Get list of items from calls
    const resultData: Screen[] = get( queryResponse, 'listAdminScreens.items', [] );
    
    if (resultData && resultData.length) {
      adminScreenVersionData = [...adminScreenVersionData, ...resultData];
    }

    // Get next token and kill if undefined
    nextToken = get(queryResponse, 'listAdminScreens.nextToken', {});
    if (!nextToken) {
      keepFetching = false;
    }
  }
  return adminScreenVersionData;
}

export const ScreenContextProvider = (props: ScreenProviderProps) => {
  const {children} = props
  const [appStore, dispatch] = useReducer(reducerWrapper, initialState);
  const variables = {};

  const [ AddAdminScreen, {loading: createAdminScreenLoading, error: createAdminScreenError}] = useMutation<
  API.CreateAdminScreenMutation,
  API.CreateAdminScreenMutationVariables>(addAdminScreen)
  
  const [AddScreen, {loading: createScreenLoading, error: createScreenError, data: newScreen}] = useMutation<
  API.CreateScreenMutation,
  API.CreateScreenMutationVariables>(addScreen, {
    onCompleted(newScreen) {
      if (newScreen && newScreen.createScreen) {
        refetch()
      }
    }
  })

  const { loading, error, data: queryResponse, refetch, fetchMore } = useQuery<
    API.ListScreensQuery,
    API.ListScreensQueryVariables
  >(listAdminScreens, { variables, fetchPolicy: 'cache-and-network', });

  React.useEffect(() => {
    if (queryResponse ) {
      let screens: Screen[] = get( queryResponse, 'listAdminScreens.items', {} );
      let nextToken: string | undefined = get( queryResponse, 'listAdminScreens.nextToken', {} );

      if (nextToken) {
        let remainingScreens: Screen[] = [];
        (async () => {
          remainingScreens = (await fetchRemainingAdminScreens(
            nextToken,
            variables,
            fetchMore
          )) as Screen[];

          screens = [ ...screens, ...remainingScreens, ];
        })();
      }

      const newState: { [key: string]: any } = { screens };

      dispatch({ type: 'setQueryResponse', payload: {...newState} });
    }
  }, [queryResponse, fetchMore, newScreen]);

  const createAdminScreen = (id: string) => {
    AddAdminScreen({variables: { input: {id, updatedAt: (new Date()).toISOString()}}});
  };

  const createScreen = (type: string) => {
    AddScreen({variables: { input: {type: type}}});
  }

  return (
    <ScreenContext.Provider
      value={{
        appStore,
        loading,
        createAdminScreenLoading,
        createAdminScreenError,
        createScreenLoading,
        createScreenError,
        createScreen,
        createAdminScreen,
        error,
      }}
    >
      <ScreenActionsContext.Provider value={{ dispatch }}>
        {children}
      </ScreenActionsContext.Provider>
    </ScreenContext.Provider>
  );
};