import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    textLink: {
      color: theme.palette.secondary.dark
    }
  });

interface Props extends WithStyles<typeof styles> {
  year: number;
}

const Copyright = ({ classes, year }: Props) => {
  return (
    <Typography variant="body1" component="span">
      {`© ${year} `}
      <a className={classes.textLink} href="https://wondery.com/">
        Wondery
      </a>
      {`. All rights reserved.`}
    </Typography>
  );
};

Copyright.defaultProps = {
  year: new Date().getFullYear()
};

export default withStyles(styles)(Copyright);
