import {
  AppBar,
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Popover,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import classnames from "classnames";
import { get } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { drawerWidth } from "../../helpers/NavHelper";
import { AuthContext } from "../../hooks/AuthContext";
import { LayoutContext } from "../../hooks/LayoutContext";
import { ScreenContextProvider } from '../../hooks/ScreenContext'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import NavPrimary from "../NavPrimary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContent: {
      display: 'flex'
    },
    action: {
      '& svg': {
        fontSize: '2.4rem'
      }
    },
    appBar: {
      backgroundColor: theme.palette.secondary.dark
    },
    appBarUnderline: {
      height: 4,
      background: theme.palette.primary.main
    },
    appBarLoggedOut: {
      backgroundColor: theme.palette.secondary.dark,
      marginLeft: drawerWidth
    },
    gutters: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      justifyContent: 'space-between'
    },
    hasRightDrawer: {
      [theme.breakpoints.up('md')]: {
        paddingRight: drawerWidth
      }
    },
    logotype: {
      display: 'flex'
    },
    logotypeWrapper: {
      textAlign: 'left',
      height: 19
    },
    menuSection: {
      display: 'flex',
      color: theme.palette.common.white
    },
    menuPaper: {
      width: 344,
      borderRadius: 0,
      padding: theme.spacing(4)
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.common.white
    },
    logoutButton: {
      marginLeft: theme.spacing(-1)
    },
    title: {
      flexGrow: 1,
      color: theme.palette.common.white
    },
    menuLink: {
      marginRight: theme.spacing(-1),
      textDecoration: 'none'
    },
    menuStorageUsage: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    menuStoragePercentage: {
      borderRadius: 2,
      marginBottom: theme.spacing(0.75),
      background: theme.palette.grey[600]
    },
    menuStoragePercentageBar: {
      borderRadius: 2
    },
    menuTierName: {
      textTransform: 'uppercase'
    },
    menuDivider: {
      paddingLeft: '100%',
      margin: theme.spacing(1.5, 0)
    }
  })
);

const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  if (!layoutContext) {
    throw new Error("Layout context is missing");
  }
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      "Header component must be used within a Auth Context Provider"
    );
  }
  const { isAuthorized, signedIn, userInfo } = authContext;
  const userEmail = get(userInfo, "email");

  const logoutAction = useCallback(() => {
    handleMenuClose();
    Auth.signOut();
    window.location.reload();
  }, []);
  if (!signedIn) {
    return null;
  }
  const { rightDrawerOpen, loading, leftDrawerOpen, setLeftDrawerOpen } = layoutContext;

  const handleDrawerToggle = () => {
    setLeftDrawerOpen(!leftDrawerOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="relative"
        className={classnames(
          classes.headerContent,
          { [classes.appBar]: signedIn },
          { [classes.appBarLoggedOut]: !signedIn },
          { [classes.hasRightDrawer]: rightDrawerOpen }
        )}
        elevation={0}
        color="default"
        component="div"
      >
        <Toolbar classes={{ gutters: classes.gutters }} role="banner">
        {isAuthorized && signedIn && (
          <IconButton 
            edge="start" 
            className={classes.menuButton} 
            color="inherit" 
            aria-haspopup
            aria-expanded={leftDrawerOpen}
            aria-controls="nav-primary-drawer"
            aria-label="Open navigation drawer"
            onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" className={classes.title}>
            Wondery Admin
          </Typography>
          {isAuthorized && signedIn && (
            <div className={classes.menuSection}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuOpen ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
              >
                <AccountCircleIcon color="inherit" />
                <ArrowDropDownIcon color="inherit" />
              </IconButton>
              <Popover
                id={"header-popover"}
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{ paper: classes.menuPaper }}
              >
                <Grid container direction="row" justify="space-around">
                  <Grid item xs={12}>
                    <Typography variant="h4" component="div">
                      Hello,
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="div">
                      {userEmail}
                    </Typography>
                  </Grid>
                  <Divider className={classes.menuDivider} />
                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        className={classes.logoutButton}
                        onClick={logoutAction}
                      >
                        Log Out
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Popover>
            </div>
          )}
        </Toolbar>
        <div className={classes.appBarUnderline}>
          {loading ? <LinearProgress /> : null}
        </div>
      </AppBar>
      {isAuthorized && <ScreenContextProvider><NavPrimary /></ScreenContextProvider>}
    </>
  );
};
export default Header;
