import createMuiTheme, {
  ThemeOptions
} from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
function createMyTheme(options: ThemeOptions) {
  return createMuiTheme({ ...options });
}

export const palette = {
  primary: {
    main: '#b9d3d4',
    dark: '#93b7b9',
    50: '#f7fafa',
    100: '#eaf2f2',
    200: '#dce9ea',
    300: '#cee0e1',
    400: '#c4dada',
    500: '#b9d3d4',
    600: '#b2cecf',
    700: '#aac8c9',
    800: '#a2c2c3',
    900: '#93b7b9',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#eefeff',
    A700: '#d4fdff',
    contrastDefaultColor: 'dark',
  },
  secondary: {
    main: '#094b4e',
    light: '#84A5A7',
    dark: '#022325',
    50: '#e1e9ea',
    100: '#b5c9ca',
    200: '#84a5a7',
    300: '#538183',
    400: '#2e6669',
    500: '#094b4e',
    600: '#084447',
    700: '#063b3d',
    800: '#053335',
    900: '#022325',
    A100: '#60f8ff',
    A200: '#2df6ff',
    A400: '#00eef9',
    A700: '#00d6e0',
    contrastDefaultColor: 'light',
  },
  grey: {
    100: '#f3f3f3',
    200: '#dad9d8',
    300: 'rgba(0, 0, 0, 0.38)',
    400: '#e4e4e5',
    500: '#ebebeb',
    600: '#d8d8d8',
    700: '#262626',
    800: '#4a4a4a',
    900: '#404041',
    A100: '#979797'
  },
  error: {
    main: '#c92727'
  },
  background: {
    paper: '#fff',
    default: '#fcfcfc'
  }
};
export const typography = {
  fontFamily: '"Open Sans", sans-serif',
  htmlFontSize: 10,
};

const MuiButtonBase: Overrides['MuiButtonBase'] = {
  root: {
    outline: '',
    WebkitTapHighlightColor: '',
    '-moz-appearance': '',
    '-webkit-appearance': '',
    '&::-moz-focus-inner': {
      borderStyle: ''
    }
  }
};

const overrides = {
  MuiButtonBase, // WCAG 2 compliance, enables visual focus state
};

let theme = createMyTheme({
  typography,
  palette,
  overrides
});

export default theme
