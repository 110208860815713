import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classnames from "classnames";
import React, { lazy, Suspense, useContext } from "react";
import Helmet from "react-helmet";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import Div100vh from "react-div-100vh";
import ErrorBoundary from "./components/ErrorBoundary";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { drawerWidth } from "./helpers/NavHelper";
import PrivateRoute from "./components/PrivateRoute";
import { getPageTitle } from "./helpers/DataHelper";
import { PrivateScreenRoutes, PublicScreenRoutes } from "./helpers/RouteHelper";
import { AuthContext } from "./hooks/AuthContext";
import { LayoutContext } from "./hooks/LayoutContext";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

moment.tz.setDefault('America/Los_Angeles')

const NotFound = lazy(() => import("./screens/NotFound"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    App: {
      textAlign: "left",
    },
    mainContentSignedIn: {
      transition: theme.transitions.create(["padding", "margin"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hasRightDrawer: {},
    leftDrawerOpen: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: drawerWidth,
      },
    },
    Site: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    SiteContent: {
      flex: "1 0 auto",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&::after": {
        content: "' '",
        display: "block",
        height: 0,
        visibility: "hidden",
      },
    },
    pageTitle: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(5),
      },
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      margin: theme.spacing(4),
    },
    screenContainer: {},
    suspenseLoader: {
      zIndex: theme.zIndex.appBar,
      marginTop: theme.spacing(-0.5),
    },
  })
);

interface Props extends RouteComponentProps {
  authState?: string;
}

const App = (props: Props) => {
  const { authState } = props;
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  if (!authContext) {
    throw new Error(
      "App component must be used within a Auth Context Provider"
    );
  }
  if (!layoutContext) {
    throw new Error(
      "App component must be used within a Layout Context Provider"
    );
  }
  const { signedIn, isAuthorized } = authContext;
  const { pageTitle, rightDrawerOpen, leftDrawerOpen } = layoutContext;

  if (authState !== "signedIn") {
    return null;
  }
  if (!(signedIn && isAuthorized)) {
    return <LinearProgress />;
  }
  return (
    <ErrorBoundary>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Div100vh>
        <div
          className={classnames([
            classes.App,
            classes.Site,
            {
              [classes.hasRightDrawer]: rightDrawerOpen,
            },
          ])}
        >
          <Helmet>
            <title>{getPageTitle(pageTitle)}</title>
          </Helmet>
          <div className={classes.SiteContent}>
            <Header />
            <Suspense
              fallback={<LinearProgress className={classes.suspenseLoader} />}
            >
              <div
                role="main"
                className={classnames(classes.mainContent, {
                  [classes.contentShift]: rightDrawerOpen,
                  [classes.mainContentSignedIn]: isAuthorized && signedIn,
                  [classes.leftDrawerOpen]: leftDrawerOpen,
                })}
              >
                <div className={classnames([classes.screenContainer])}>
                  <Switch>
                    {PrivateScreenRoutes.map((props, index) => (
                      <PrivateRoute {...props} key={index} />
                    ))}
                    {PublicScreenRoutes.map((props, index) => (
                      <Route {...props} key={index} />
                    ))}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Suspense>
          </div>
          <div role="contentinfo">{<Footer />}</div>
        </div>
      </Div100vh>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
};

export default withRouter(App);
