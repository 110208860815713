import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AuthContext } from '../../hooks/AuthContext';
import Empty from '../../screens/Empty';

export default ({ component, ...rest }: RouteProps) => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      'PrivateRoute component must be used within a Auth Context Provider'
    );
  }
  const { isAuthorized } = authContext;
  return (
    <Route {...rest} component={isAuthorized ? component : () => <Empty />} />
  );
};
