import React, { createContext, ReactChild, useState } from "react";

export interface LayoutContextExports {
  loading: boolean;
  pageTitle: string;
  rightDrawerOpen: boolean;
  leftDrawerOpen: boolean;
  setLoading: React.Dispatch<any>;
  setPageTitle: React.Dispatch<any>;
  setRightDrawerOpen: React.Dispatch<any>;
  setLeftDrawerOpen: React.Dispatch<any>;
}

export const LayoutContext = createContext<LayoutContextExports | undefined>(
  undefined
);
const { Provider } = LayoutContext;

export interface LayoutProviderProps {
  children?: ReactChild;
}

export const LayoutProvider = (props: LayoutProviderProps) => {
  const [pageTitle, setPageTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);

  const defaultContext = {
    loading,
    pageTitle,
    setLoading,
    setPageTitle,
    rightDrawerOpen,
    setRightDrawerOpen,
    leftDrawerOpen,
    setLeftDrawerOpen,
  };
  return (
    <Provider value={defaultContext}>
      {props.children && props.children}
    </Provider>
  );
};
