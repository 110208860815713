import React from 'react';
import ReactDOM from 'react-dom';
import '@aws-amplify/ui/dist/style.css';
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient from 'aws-appsync';
import * as log from 'loglevel';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import amplifyConfig from './aws-exports';
import * as serviceWorker from './serviceWorker';
import AppWithAuth from './components/AppWithAuth';
import { AuthProvider } from './hooks/AuthContext';
import { LayoutProvider } from './hooks/LayoutContext';
import materialUiTheme from './theme/materialUi';
import { CONTENT_BUCKET_NAME } from './constants/Configs';
import './styles.css';

/* 
  TODO: this errors out when it is introduced
  Unhandled Rejection (TypeError): this.context.client is undefined
  import { Rehydrated } from "aws-appsync-react"; // this needs to also be installed when working with React
*/

const API_HOST = `${process.env.REACT_APP_API_HOST ||
  'https://tria3ssw4b.execute-api.us-west-2.amazonaws.com/develop'}`;

const CONTENT_BUCKET = `${CONTENT_BUCKET_NAME}-${process.env.REACT_APP_DEPLOY_ENV || 'develop'}`;
console.log('REACT_APP_DEPLOY_ENV: ', CONTENT_BUCKET, process.env.REACT_APP_DEPLOY_ENV);
log.setLevel('trace');

Amplify.configure({ 
  ...amplifyConfig, 
  clientMetadata: { source: 'cms' },
  Storage: {
    AWSS3: {
      bucket: `${CONTENT_BUCKET}`, 
      region: 'us-west-2', 
    },
    customPrefix: {
      public: 'homeScreen_hero/'
    }
  }
});

const client = new AWSAppSyncClient({
  url: amplifyConfig.aws_appsync_graphqlEndpoint,
  region: amplifyConfig.aws_appsync_region,
  auth: {
    // @ts-ignore https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/474
    type: amplifyConfig.aws_appsync_authenticationType,
    jwtToken: async () =>
      // @ts-ignore
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  disableOffline: true,
  fetchPolicy: 'network-only',
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={materialUiTheme}>
      <CssBaseline />
      <BrowserRouter>
        <AuthProvider>
          <LayoutProvider>
            <AppWithAuth />
          </LayoutProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
