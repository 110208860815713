import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../hooks/LayoutContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {}
  })
);

const Empty = () => {
  const classes = useStyles();
  const pageTitle = 'Empty';
  const layoutContext = useContext(LayoutContext);
  if (!layoutContext) {
    throw new Error('Layout context is missing');
  }
  const { setPageTitle } = layoutContext;
  useEffect(() => {
    setPageTitle(pageTitle);
  }, [setPageTitle]);

  return <div className={classes.root} data-testid="screen-empty" />;
};
export default Empty;
