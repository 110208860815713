
/**
 * ADMIN SCREEN LABELS
 */

///
// LIST HEADERS
//
export const ADMIN_SCREEN_LIST_PUBLISHED            :string = 'Published';
export const ADMIN_SCREEN_LIST_SCHEDULED            :string = 'Scheduled';
export const ADMIN_SCREEN_LIST_DRAFT                :string = 'Draft';
export const ADMIN_SCREEN_LIST_ARCHIVED             :string = 'Archived';

///
// LIST COLUMNS
//
export const ADMIN_SCREEN_LIST_COLUMN_NAME          :string = 'Name';
export const ADMIN_SCREEN_LIST_COLUMN_PUBLISHED_ON  :string = 'Published On';
export const ADMIN_SCREEN_LIST_COLUMN_SCHEDULED_FOR :string = 'Scheduled For';
export const ADMIN_SCREEN_LIST_COLUMN_CREATED       :string = 'Created';
export const ADMIN_SCREEN_LIST_COLUMN_PUBLISHED     :string = 'Published';
export const ADMIN_SCREEN_LIST_COLUMN_ARCHIVED      :string = 'Archived';
export const ADMIN_SCREEN_LIST_COLUMN_UPDATED_BY    :string = 'Updated by';
export const ADMIN_SCREEN_LIST_COLUMN_AVAILABLE     :string = 'Available until';

///
// LIST BUTTONS
//
export const ADMIN_SCREEN_LIST_BTN_CLONE            :string = 'Clone';
export const ADMIN_SCREEN_LIST_BTN_EDIT             :string = 'Edit';
export const ADMIN_SCREEN_LIST_BTN_VIEW             :string = 'View';

///
// RADIO BUTTONS
//
export const ADMIN_SCREEN_LIST_RADIO_CLONE          :string = 'Clone';
export const ADMIN_SCREEN_LIST_RADIO_NEW_DRAFT      :string = 'New Draft';


///
// MISC ADMIN LIST
//

// Since old admin screen versions don't have a name we'll use this instead
export const ADMIN_SCREEN_LEGACY_COLLECTION         :string = 'Legacy Mobile Screen';

type AdminScreenListLabelItem = {
  title:string;
  name:string;
  created:string;
  customColumnLabel?: string | undefined;
  customColumnLabel2?: string | undefined;
};

export const ADMIN_SCREEN_LIST_LABELS:{[key:string]:AdminScreenListLabelItem} = {
  published: {
    title: ADMIN_SCREEN_LIST_PUBLISHED,
    name: ADMIN_SCREEN_LIST_COLUMN_NAME,
    created: ADMIN_SCREEN_LIST_COLUMN_PUBLISHED_ON,
    customColumnLabel: ADMIN_SCREEN_LIST_COLUMN_UPDATED_BY

  },
  scheduled: {
    title: ADMIN_SCREEN_LIST_SCHEDULED,
    name: ADMIN_SCREEN_LIST_COLUMN_NAME,
    created: ADMIN_SCREEN_LIST_COLUMN_SCHEDULED_FOR,
    customColumnLabel: ADMIN_SCREEN_LIST_COLUMN_UPDATED_BY
  },
  draft: {
    title: ADMIN_SCREEN_LIST_DRAFT,
    name: ADMIN_SCREEN_LIST_COLUMN_NAME,
    created: ADMIN_SCREEN_LIST_COLUMN_CREATED,
    customColumnLabel: ADMIN_SCREEN_LIST_COLUMN_UPDATED_BY
  },
  unpublished: {
    title: ADMIN_SCREEN_LIST_ARCHIVED,
    name: ADMIN_SCREEN_LIST_COLUMN_NAME,
    created: ADMIN_SCREEN_LIST_COLUMN_PUBLISHED,
    customColumnLabel: ADMIN_SCREEN_LIST_COLUMN_ARCHIVED
  }
};

/**
 * DATE LABELS
 */

export const DATE_MONTH_JANUARY     :string = 'January';
export const DATE_MONTH_FEBRUARY    :string = 'February';
export const DATE_MONTH_MARCH       :string = 'March';
export const DATE_MONTH_APRIL       :string = 'April';
export const DATE_MONTH_MAY         :string = 'May';
export const DATE_MONTH_JUNE        :string = 'June';
export const DATE_MONTH_JULY        :string = 'July';
export const DATE_MONTH_AUGUST      :string = 'August';
export const DATE_MONTH_SEPTEMBER   :string = 'September';
export const DATE_MONTH_OCTOBER     :string = 'October';
export const DATE_MONTH_NOVEMBER    :string = 'November';
export const DATE_MONTH_DECEMBER    :string = 'December';

export const DATE_MONTHS:string[] = [
  DATE_MONTH_JANUARY,     DATE_MONTH_FEBRUARY,
  DATE_MONTH_MARCH,       DATE_MONTH_APRIL,
  DATE_MONTH_MAY,         DATE_MONTH_JUNE,
  DATE_MONTH_JULY,        DATE_MONTH_AUGUST,
  DATE_MONTH_SEPTEMBER,   DATE_MONTH_OCTOBER,
  DATE_MONTH_NOVEMBER,    DATE_MONTH_DECEMBER
];

export const DATE_DAY_OF_WEEK_SUNDAY    :string = 'Sunday';
export const DATE_DAY_OF_WEEK_MONDAY    :string = 'Monday';
export const DATE_DAY_OF_WEEK_TUESDAY   :string = 'Tuesday';
export const DATE_DAY_OF_WEEK_WEDNESDAY :string = 'Wednesday';
export const DATE_DAY_OF_WEEK_THURSDAY  :string = 'Thursday';
export const DATE_DAY_OF_WEEK_FRIDAY    :string = 'Friday';
export const DATE_DAY_OF_WEEK_SATURDAY  :string = 'Saturday';

export const DATE_DAY_OF_WEEK:string[] = [
  DATE_DAY_OF_WEEK_SUNDAY,    DATE_DAY_OF_WEEK_MONDAY,
  DATE_DAY_OF_WEEK_TUESDAY,   DATE_DAY_OF_WEEK_WEDNESDAY,
  DATE_DAY_OF_WEEK_THURSDAY,  DATE_DAY_OF_WEEK_FRIDAY,
  DATE_DAY_OF_WEEK_SATURDAY,
];

/**
 * CURATED MIX LABELS
 */

///
// LIST HEADERS
//
export const CURATED_MIX_LIST_SERIES            :string = 'Series';

///
// LIST COLUMNS
//
export const CURATED_MIX_LIST_COLUMN_NAME          :string = 'Name';
export const CURATED_MIX_LIST_COLUMN_CREATED       :string = 'Created';

type CuratedMixListLabelItem = {
  title:string;
  name:string;
  created:string;
};

export const CURATED_MIX_LIST_LABELS:CuratedMixListLabelItem = {
  title: CURATED_MIX_LIST_SERIES,
  name: CURATED_MIX_LIST_COLUMN_NAME,
  created: CURATED_MIX_LIST_COLUMN_CREATED,
};