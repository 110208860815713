import gql from 'graphql-tag';

export const addAdminScreenVersion = gql`
  mutation CreateAdminScreenVersionInput(
    $input: CreateAdminScreenVersionInput!
  ) {
    createAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const updateAdminScreenVersion = gql`
  mutation UpdateAdminScreenVersionInput(
    $input: UpdateAdminScreenVersionInput!
  ) {
    updateAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const deleteAdminScreenVersion = gql`
  mutation DeleteAdminScreenVersionInput(
    $input: DeleteAdminScreenVersionInput!
  ) {
    deleteAdminScreenVersion(input: $input) {
      id
      adminScreenID
      updatedAt
      name
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
    }
  }
`;

export const addAdminScreen = gql`
  mutation CreateAdminScreenInput($input: CreateAdminScreenInput!) {
    createAdminScreen(input: $input) {
      id
      updatedAt
    }
  }
`;

export const addScreen = gql`
  mutation CreateScreenInput($input: CreateScreenInput!) {
    createScreen(input: $input) {
      type
      updatedAt
    }
  }
`;

export const createAccountDeletionRequest = /* GraphQL */ `
  mutation CreateAccountDeletionRequest(
    $input: CreateAccountDeletionRequestInput!
    $condition: ModelAccountDeletionRequestConditionInput
  ) {
    createAccountDeletionRequest(input: $input, condition: $condition) {
      id
      updatedAt
      cognitoStatus
      createdAt
      user
    }
  }
`;

export const addCuratedMix = gql`
  mutation CreateCuratedMixInput($input: CreateCuratedMixInput!) {
    createCuratedMix(input: $input) {
      id
      title
      description
      markets
      createdAt
      updatedAt
    }
  }
`;

export const updateCuratedMix = gql`
  mutation UpdateCuratedMixInput($input: UpdateCuratedMixInput!) {
    updateCuratedMix(input: $input) {
      id
      title
      description
      markets
      createdAt
      updatedAt
    }
  }
`;

export const addCuratedMixEpisode = gql`
  mutation CreateCuratedMixEpisodeInput($input: CreateCuratedMixEpisodeInput!) {
    createCuratedMixEpisode(input: $input) {
      id
      curatedMixId
      episodeId
      createdAt
      updatedAt
      order
    }
  }
`;

export const updateCuratedMixEpisode = gql`
  mutation UpdateCuratedMixEpisodeInput($input: UpdateCuratedMixEpisodeInput!) {
    updateCuratedMixEpisode(input: $input) {
      id
      curatedMixId
      episodeId
      createdAt
      updatedAt
      order
    }
  }
`;

export const deleteCuratedMix = gql`
  mutation DeleteCuratedMixInput(
    $input: DeleteCuratedMixInput!
    $condition: ModelCuratedMixConditionInput
  ) {
    deleteCuratedMix(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCuratedMixEpisode = gql`
  mutation DeleteCuratedMixEpisodeInput(
    $input: DeleteCuratedMixEpisodeInput!
    $condition: ModelCuratedMixEpisodeConditionInput
  ) {
    deleteCuratedMixEpisode(input: $input, condition: $condition) {
      id
      curatedMixId
      episodeId
    }
  }
`;

export const deleteCuratedMixSeries = gql`
  mutation DeleteCuratedMixSeriesInput(
    $input: DeleteCuratedMixSeriesInput!
    $condition: ModelCuratedMixSeriesConditionInput
  ) {
    deleteCuratedMixSeries(input: $input, condition: $condition) {
      id
      curatedMixId
      seriesId
    }
  }
`;

export const addDiscoveryScreen = gql`
  mutation CreateDiscoveryScreenInput(
    $input: CreateDiscoveryScreenInput!
  ) {
    createDiscoveryScreen(input: $input) {
      id
      title
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateDiscoveryScreen = gql`
mutation UpdateDiscoveryScreenInput(
  $input: UpdateDiscoveryScreenInput!
) {
  id
  title
  status
  createdAt
  updatedAt
}`; 

export const addDiscoveryScreenRow = gql`
  mutation CreateDiscoveryScreenRow(
    $input: CreateDiscoveryScreenRowInput!
    $condition: ModelDiscoveryScreenRowConditionInput
  ) {
    createDiscoveryScreenRow(input: $input, condition: $condition) {
      id
      title
      rowType
      logicType
      indexType
      order
      discoveryScreenId
      dynamicContentCollectionConfId
      createdAt
      updatedAt
      items {
        items {
          id
          active
          contentCollectionID
          exclusiveSeason
          order
          updatedAt
          ctaLabel
          secondaryCtaLabel
          tab
          appearance
          createdAt
        }
        nextToken
      }
    }
  }
`;

export const updateDiscoveryScreenRow = gql`
mutation UpdateDiscoveryScreenRowInput(
  $input: UpdateDiscoveryScreenRowInput!
  $condition: ModelDiscoveryScreenRowConditionInput
) {
    id
    title
    rowType
    logicType
    indexType
    order
    discoveryScreenId
    createdAt
    updatedAt
}`; 

export const createContentCollectionItem =gql`
  mutation CreateContentCollectionItem(
    $input: CreateContentCollectionItemInput!
    $condition: ModelContentCollectionItemConditionInput
  ) {
    createContentCollectionItem(input: $input, condition: $condition) {
      id
      active
      contentCollectionID
      exclusiveSeason
      images {
        url
        height
        width
        source
        style
      }
      order
      updatedAt
      ctaLabel
      secondaryCtaLabel
      tab
      appearance
      action {
        actionType
        destination
        platform
      }
      createdAt
      contentCollection {
        id
        label
        body
        type
        deviceTargets {
          platform
          minOSVersion
          maxOSVersion
        }
        targetEntitlement
        keywords
        endCardsCollectionID
        createdAt
        updatedAt
        contentCollectionItems {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        endCards {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        screens {
          items {
            id
            active
            contentCollectionID
            screenType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        series {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      curatedMix {
        id
        title
        description
        createdAt
        updatedAt
      }
      episode {
        id
        cinemagraphs {
          url
          contentType
        }
        historyEpisodeId
        guid
        description
        descriptionPlus
        duration
        durationPlus
        episodeNumber
        episodeType
        episodeTypeString
        images {
          url
          height
          width
          source
          style
        }
        listeningHistory {
          items {
            id
            episodeID
            percentageCompleted
            seriesID
            updatedAt
            user
            saved
            createdAt
          }
          nextToken
        }
        media {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        mediaPlus {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        publishDate
        publishDatePlus
        releaseDate
        seasonID
        seriesID
        title
        titleRaw
        videoTrailers {
          url
          contentType
        }
        sourceGuid
        sourceGuidPlus
        keywords
        mediaFeatureKeywords
        slug
        explicit
        seasonNumber
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        popularity {
          id
          allTime
          daily
          monthly
          periscopeEpisodeID
          weekly
          updatedAt
          seriesID
          createdAt
          episode {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        releases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        season {
          id
          art19FeedPlusSeasonID
          art19FeedSeasonID
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          images {
            url
            height
            width
            source
            style
          }
          seasonNumber
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          seriesID
          title
          latestMonthlyPopularity
          childFriendly
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      genre {
        id
        description
        name
        createdAt
        updatedAt
        series {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      season {
        id
        art19FeedPlusSeasonID
        art19FeedSeasonID
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        images {
          url
          height
          width
          source
          style
        }
        seasonNumber
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        seriesID
        title
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeasonID
          weekly
          updatedAt
          seriesID
          createdAt
          season {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      series {
        id
        adFreeEpisodesAvailable
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        exclusive
        exclusiveSeasons
        exclusiveEarlyAccessEpisodes
        exclusiveBonusEpisodes
        favorites {
          items {
            id
            seriesID
            status
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        featuredEpisode
        featuredSeason
        frequency
        images {
          url
          height
          width
          source
          style
        }
        order
        seasonCount
        seasonality
        slug
        title
        videoTrailers {
          url
          contentType
        }
        keywords
        genreKeywords
        networkKeywords
        mediaFeatureKeywords
        latestEpisodeReleaseDate
        explicit
        latestMonthlyPopularity
        language
        childFriendly
        markets
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        episodePopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeEpisodeID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        episodeReleases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        genres {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
        hosts {
          items {
            id
            seriesID
            hostID
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        networks {
          items {
            id
            seriesID
            networkID
            createdAt
            updatedAt
          }
          nextToken
        }
        seasons {
          items {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        seasonPopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        contentCollections {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeriesID
          weekly
          updatedAt
          createdAt
        }
      }
    }
  }
`;
export const updateContentCollectionItem = /* GraphQL */ `
  mutation UpdateContentCollectionItem(
    $input: UpdateContentCollectionItemInput!
    $condition: ModelContentCollectionItemConditionInput
  ) {
    updateContentCollectionItem(input: $input, condition: $condition) {
      id
      active
      contentCollectionID
      exclusiveSeason
      images {
        url
        height
        width
        source
        style
      }
      order
      updatedAt
      ctaLabel
      secondaryCtaLabel
      tab
      appearance
      action {
        actionType
        destination
        platform
      }
      createdAt
      contentCollection {
        id
        label
        body
        type
        deviceTargets {
          platform
          minOSVersion
          maxOSVersion
        }
        targetEntitlement
        keywords
        endCardsCollectionID
        createdAt
        updatedAt
        contentCollectionItems {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        endCards {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        screens {
          items {
            id
            active
            contentCollectionID
            screenType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        series {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      curatedMix {
        id
        title
        description
        createdAt
        updatedAt
      }
      episode {
        id
        cinemagraphs {
          url
          contentType
        }
        historyEpisodeId
        guid
        description
        descriptionPlus
        duration
        durationPlus
        episodeNumber
        episodeType
        episodeTypeString
        images {
          url
          height
          width
          source
          style
        }
        listeningHistory {
          items {
            id
            episodeID
            percentageCompleted
            seriesID
            updatedAt
            user
            saved
            createdAt
          }
          nextToken
        }
        media {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        mediaPlus {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        publishDate
        publishDatePlus
        releaseDate
        seasonID
        seriesID
        title
        titleRaw
        videoTrailers {
          url
          contentType
        }
        sourceGuid
        sourceGuidPlus
        keywords
        mediaFeatureKeywords
        slug
        explicit
        seasonNumber
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        popularity {
          id
          allTime
          daily
          monthly
          periscopeEpisodeID
          weekly
          updatedAt
          seriesID
          createdAt
          episode {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        releases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        season {
          id
          art19FeedPlusSeasonID
          art19FeedSeasonID
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          images {
            url
            height
            width
            source
            style
          }
          seasonNumber
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          seriesID
          title
          latestMonthlyPopularity
          childFriendly
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      genre {
        id
        description
        name
        createdAt
        updatedAt
        series {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      season {
        id
        art19FeedPlusSeasonID
        art19FeedSeasonID
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        images {
          url
          height
          width
          source
          style
        }
        seasonNumber
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        seriesID
        title
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeasonID
          weekly
          updatedAt
          seriesID
          createdAt
          season {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      series {
        id
        adFreeEpisodesAvailable
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        exclusive
        exclusiveSeasons
        exclusiveEarlyAccessEpisodes
        exclusiveBonusEpisodes
        favorites {
          items {
            id
            seriesID
            status
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        featuredEpisode
        featuredSeason
        frequency
        images {
          url
          height
          width
          source
          style
        }
        order
        seasonCount
        seasonality
        slug
        title
        videoTrailers {
          url
          contentType
        }
        keywords
        genreKeywords
        networkKeywords
        mediaFeatureKeywords
        latestEpisodeReleaseDate
        explicit
        latestMonthlyPopularity
        language
        childFriendly
        markets
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        episodePopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeEpisodeID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        episodeReleases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        genres {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
        hosts {
          items {
            id
            seriesID
            hostID
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        networks {
          items {
            id
            seriesID
            networkID
            createdAt
            updatedAt
          }
          nextToken
        }
        seasons {
          items {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        seasonPopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        contentCollections {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeriesID
          weekly
          updatedAt
          createdAt
        }
      }
    }
  }
`;
export const deleteContentCollectionItem = /* GraphQL */ `
  mutation DeleteContentCollectionItem(
    $input: DeleteContentCollectionItemInput!
    $condition: ModelContentCollectionItemConditionInput
  ) {
    deleteContentCollectionItem(input: $input, condition: $condition) {
      id
      active
      contentCollectionID
      exclusiveSeason
      images {
        url
        height
        width
        source
        style
      }
      order
      updatedAt
      ctaLabel
      secondaryCtaLabel
      tab
      appearance
      action {
        actionType
        destination
        platform
      }
      createdAt
      contentCollection {
        id
        label
        body
        type
        deviceTargets {
          platform
          minOSVersion
          maxOSVersion
        }
        targetEntitlement
        keywords
        endCardsCollectionID
        createdAt
        updatedAt
        contentCollectionItems {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        endCards {
          items {
            id
            active
            contentCollectionID
            exclusiveSeason
            order
            updatedAt
            ctaLabel
            secondaryCtaLabel
            tab
            appearance
            createdAt
          }
          nextToken
        }
        screens {
          items {
            id
            active
            contentCollectionID
            screenType
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        series {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      curatedMix {
        id
        title
        description
        createdAt
        updatedAt
      }
      episode {
        id
        cinemagraphs {
          url
          contentType
        }
        historyEpisodeId
        guid
        description
        descriptionPlus
        duration
        durationPlus
        episodeNumber
        episodeType
        episodeTypeString
        images {
          url
          height
          width
          source
          style
        }
        listeningHistory {
          items {
            id
            episodeID
            percentageCompleted
            seriesID
            updatedAt
            user
            saved
            createdAt
          }
          nextToken
        }
        media {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        mediaPlus {
          url {
            url
            contentType
            style
            dataSource
            featureName
            secureHost
          }
          resolution
        }
        publishDate
        publishDatePlus
        releaseDate
        seasonID
        seriesID
        title
        titleRaw
        videoTrailers {
          url
          contentType
        }
        sourceGuid
        sourceGuidPlus
        keywords
        mediaFeatureKeywords
        slug
        explicit
        seasonNumber
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        popularity {
          id
          allTime
          daily
          monthly
          periscopeEpisodeID
          weekly
          updatedAt
          seriesID
          createdAt
          episode {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        releases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        season {
          id
          art19FeedPlusSeasonID
          art19FeedSeasonID
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          images {
            url
            height
            width
            source
            style
          }
          seasonNumber
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          seriesID
          title
          latestMonthlyPopularity
          childFriendly
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      genre {
        id
        description
        name
        createdAt
        updatedAt
        series {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      season {
        id
        art19FeedPlusSeasonID
        art19FeedSeasonID
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        images {
          url
          height
          width
          source
          style
        }
        seasonNumber
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        seriesID
        title
        latestMonthlyPopularity
        childFriendly
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeasonID
          weekly
          updatedAt
          seriesID
          createdAt
          season {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          series {
            id
            adFreeEpisodesAvailable
            description
            descriptionPlus
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            exclusive
            exclusiveSeasons
            exclusiveEarlyAccessEpisodes
            exclusiveBonusEpisodes
            featuredEpisode
            featuredSeason
            frequency
            order
            seasonCount
            seasonality
            slug
            title
            keywords
            genreKeywords
            networkKeywords
            mediaFeatureKeywords
            latestEpisodeReleaseDate
            explicit
            latestMonthlyPopularity
            language
            childFriendly
            markets
            createdAt
            updatedAt
          }
        }
        series {
          id
          adFreeEpisodesAvailable
          cinemagraphs {
            url
            contentType
          }
          description
          descriptionPlus
          episodeCount
          episodeCountFull
          episodeCountBonus
          episodeCountTrailer
          exclusive
          exclusiveSeasons
          exclusiveEarlyAccessEpisodes
          exclusiveBonusEpisodes
          favorites {
            nextToken
          }
          featuredEpisode
          featuredSeason
          frequency
          images {
            url
            height
            width
            source
            style
          }
          order
          seasonCount
          seasonality
          slug
          title
          videoTrailers {
            url
            contentType
          }
          keywords
          genreKeywords
          networkKeywords
          mediaFeatureKeywords
          latestEpisodeReleaseDate
          explicit
          latestMonthlyPopularity
          language
          childFriendly
          markets
          createdAt
          updatedAt
          episodes {
            nextToken
          }
          episodePopularity {
            nextToken
          }
          episodeReleases {
            nextToken
          }
          genres {
            nextToken
          }
          hosts {
            nextToken
          }
          networks {
            nextToken
          }
          seasons {
            nextToken
          }
          seasonPopularity {
            nextToken
          }
          contentCollections {
            nextToken
          }
          popularity {
            id
            allTime
            daily
            monthly
            periscopeSeriesID
            weekly
            updatedAt
            createdAt
          }
        }
      }
      series {
        id
        adFreeEpisodesAvailable
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        exclusive
        exclusiveSeasons
        exclusiveEarlyAccessEpisodes
        exclusiveBonusEpisodes
        favorites {
          items {
            id
            seriesID
            status
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        featuredEpisode
        featuredSeason
        frequency
        images {
          url
          height
          width
          source
          style
        }
        order
        seasonCount
        seasonality
        slug
        title
        videoTrailers {
          url
          contentType
        }
        keywords
        genreKeywords
        networkKeywords
        mediaFeatureKeywords
        latestEpisodeReleaseDate
        explicit
        latestMonthlyPopularity
        language
        childFriendly
        markets
        createdAt
        updatedAt
        episodes {
          items {
            id
            historyEpisodeId
            guid
            description
            descriptionPlus
            duration
            durationPlus
            episodeNumber
            episodeType
            episodeTypeString
            publishDate
            publishDatePlus
            releaseDate
            seasonID
            seriesID
            title
            titleRaw
            sourceGuid
            sourceGuidPlus
            keywords
            mediaFeatureKeywords
            slug
            explicit
            seasonNumber
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        episodePopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeEpisodeID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        episodeReleases {
          items {
            id
            type
            episodeID
            releaseDate
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        genres {
          items {
            id
            seriesID
            genreID
            createdAt
            updatedAt
          }
          nextToken
        }
        hosts {
          items {
            id
            seriesID
            hostID
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        networks {
          items {
            id
            seriesID
            networkID
            createdAt
            updatedAt
          }
          nextToken
        }
        seasons {
          items {
            id
            art19FeedPlusSeasonID
            art19FeedSeasonID
            description
            descriptionPlus
            seasonNumber
            episodeCount
            episodeCountFull
            episodeCountBonus
            episodeCountTrailer
            seriesID
            title
            latestMonthlyPopularity
            childFriendly
            createdAt
            updatedAt
          }
          nextToken
        }
        seasonPopularity {
          items {
            id
            allTime
            daily
            monthly
            periscopeSeasonID
            weekly
            updatedAt
            seriesID
            createdAt
          }
          nextToken
        }
        contentCollections {
          items {
            id
            active
            order
            seriesID
            contentCollectionID
            createdAt
            updatedAt
          }
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeriesID
          weekly
          updatedAt
          createdAt
        }
      }
    }
  }
`;
