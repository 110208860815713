import { matchPath } from 'react-router';
export const drawerWidth = 220;
export const isSelected = (path: string = '/', currentLocation: string) => {
  const match = matchPath(currentLocation, {
    path,
    exact: true,
    strict: false
  });
  return match && match.isExact ? true : false;
};
