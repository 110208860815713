import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from '@material-ui/core';
import * as log from 'loglevel';
import React, { Component, ReactChild } from 'react';
import { Helmet } from 'react-helmet';
import { getPageTitle } from '../../helpers/DataHelper';

const styles = (theme: Theme) =>
  createStyles({
    errorHeader: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2)
    },
    errorMessage: {
      marginBottom: theme.spacing(2)
    },
    root: {
      alignItems: 'baseline',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(12)
    }
  });

interface Props extends WithStyles<typeof styles> {
  children?: ReactChild;
  hasError?: string;
}
interface State {
  error: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(error: Error | null) {
    return { error: true };
  }
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  public componentDidCatch(error: Error | null, info: object) {
    log.warn('ErrorBoundary.componentDidCatch', error, info);
  }

  public render() {
    const pageTitle = 'Error';
    const { error } = this.state;
    const { children, hasError, classes } = this.props;
    if (!error && !hasError) {
      return children;
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{getPageTitle(pageTitle)}</title>
        </Helmet>
        <Typography component="h1" variant="h6" className={classes.errorHeader}>
          Error
        </Typography>
        <Typography
          component="span"
          variant="body1"
          className={classes.errorMessage}
        >
          The site has encountered an error{hasError && ':'}
        </Typography>
        {hasError && (
          <Typography
            component="span"
            variant="body1"
            className={classes.errorMessage}
          >
            {hasError}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ErrorBoundary);
