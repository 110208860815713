import { ICredentials } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import { get } from 'lodash';
import * as log from 'loglevel';
import React, { createContext, ReactChild, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import jwtDecode from "jwt-decode";

export interface AuthContextExports {
  signedIn: boolean;
  userInfo:
    | {
        email: string;
        sub: string;
        identityId: string;
        authTime: number | undefined;
      }
    | null
    | undefined;
  isAuthorized: boolean | undefined;
}

export const AuthContext = createContext<AuthContextExports | undefined>(
  undefined
);
export const { Provider, Consumer: AuthConsumer } = AuthContext;

function formatUserInfo(authData: any, credData: ICredentials) {
  log.debug('formatUserInfo', authData, credData);
  const { identityId } = credData;
  let data = {
    identityId,
    authTime: get(authData, 'signInUserSession.accessToken.payload.auth_time')
  };
  if (!get(authData, 'attributes.email') || !get(authData, 'attributes.sub')) {
    throw Error('Unexpected Cognito User');
  }
  return {
    ...data,
    ...get(authData || {}, 'attributes', {})
  };
}

function isValidCmsAdmin(authData: any) {
  log.debug('isValidCmsAdmin', authData);
  const jwt = get(authData, 'signInUserSession.accessToken.jwtToken');
  const decodedJwt = jwtDecode(jwt);
  const cognitoGroups = decodedJwt["cognito:groups"] || [];
  log.debug("cognito groups", cognitoGroups, jwt);
  return cognitoGroups.includes("cms");
}

export interface AuthProviderProps extends RouteComponentProps {
  children?: ReactChild;
  defaultSignedIn?: boolean;
  defaultIsAuthorized?: boolean;
  defaultPersonInfo?: any;
}

export const AuthProvider = withRouter((props: AuthProviderProps) => {
  const { history, defaultIsAuthorized, defaultSignedIn } = props;
  const [signedIn, setSignedIn] = useState<boolean>(!!defaultSignedIn);
  const [userInfo, setUserInfo] = useState(undefined);
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(defaultIsAuthorized);

  useEffect(() => {
    const fetchUser = async (user?: AuthContextExports['userInfo']) => {
      try {
        const userData = user || (await Auth.currentAuthenticatedUser());
        if (!isValidCmsAdmin(userData)) {
          throw Error("Invalid CMS Admin");
        }
        const userCredentials = await Auth.currentCredentials();
        const data = formatUserInfo(userData, userCredentials);
        setUserInfo(data);
      } catch (e) {
        log.warn('fetchUser', 'caught error', e);
      }
    };

    // all events :
    // signedIn signedOut
    // tokenRefresh tokenRefresh_failure
    // signInWithRedirect signInWithRedirect_failure
    // customOAuthState
    Hub.listen('auth', ({ payload: { event, data } }) => {
      log.debug('Hub payload:', data);
      switch (event) {
        case 'signIn': {
          setSignedIn(true);
          fetchUser();
          break;
        }
        case 'signOut': {
          setSignedIn(false);
          setIsAuthorized(false);
          history.push({ pathname: '/' });
          break;
        }
        case 'tokenRefresh_failure': {
          setSignedIn(false);
          setIsAuthorized(false);
          history.push({ pathname: '/' });
          break
        }
        case 'signIn_failure': {
          break;
        }
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        setSignedIn(true);
        fetchUser(user);
      })
      .catch((e: any) => log.warn('User is', e));
  }, [history]);

  useEffect(() => {
    log.debug('userInfo', userInfo);
    if (userInfo) {
      setIsAuthorized(!!get(userInfo || {}, 'email'));
    }
  }, [userInfo]);

  useEffect(() => {
    log.debug('Authentication Status (signedIn):', signedIn);
  }, [signedIn]);
  useEffect(() => {
    log.debug('Authorization Status (isAuthorized):', isAuthorized);
  }, [isAuthorized]);

  const defaultContext = {
    signedIn,
    userInfo,
    isAuthorized
  };
  return (
    <Provider value={defaultContext}>
      {props.children && props.children}
    </Provider>
  );
});
