import { Authenticator, Loading, SignUp, Greetings } from "aws-amplify-react";
import React from "react";
import App from "../../App";
import awsconfig from "../../aws-exports";

class AppWithAuth extends React.Component {
  render() {
    return (
        <Authenticator amplifyConfig={awsconfig} hide={[Loading, SignUp, Greetings]}>
          <App />
        </Authenticator>
    );
  }
}

export default AppWithAuth;
